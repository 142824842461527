
  import { defineComponent, ref, onMounted, onBeforeMount } from 'vue';
  import axios, { AxiosError } from 'axios';
  import useAlert from "@/composables/Alert"
  import router from '@/router';
  import {
    CampHeader,
    CampFormHeader,
    CampFormRackSubmitBtn,
  } from '@/components';
  import { useLoaderStore } from "@/store/LoaderStore";
  import { useCompanyStore } from '@/store/CompanyStore';
  import { useFilterMainStore } from '@/store/FilterMainStore';
  import { Modal } from "bootstrap";

  interface IRegionalManagement{
    name : string,
    responsible : string,
    responsible_telephone : string;
 }
  
  function checkEmptyFields(obj): boolean {
    for (let key in obj) {
      if (obj[key] === "" || obj[key] === 0) {
        return true;
      }
    }
    return false;
  }
  
  export default defineComponent({
    name: "RegionalManagementPost",
    components: {
      CampHeader,
      CampFormHeader,
      CampFormRackSubmitBtn
    },
    setup() {
      const { showTimeAlert } = useAlert()
      const group = ref<IRegionalManagement>({
        name : '',
        responsible : '',
        responsible_telephone : '',
      });
      const loaderStore = useLoaderStore();
      const companyStore = useCompanyStore();
      const filterMainStore = useFilterMainStore()
      const isRequiredField = ref(false)
      const proportion = ref()
      const modatlCroppedLogo = ref(true)
      const phoneError = ref('')
      const phoneMask = ref('+55 (##) # ####-####')

  
      // Functions
      async function onSubmitForm() {
        isRequiredField.value = false
        if(!group.value)
          return
        const requiredInputs: any = group.value
        if(checkEmptyFields(requiredInputs) || phoneError.value){
          isRequiredField.value = true
          return
        }
      loaderStore.open()
      try {
            const result = await axios.post(`/api/createManagementGroup`, group.value)
            showTimeAlert("Grupo criado com sucesso!")
            return router.push("/gestao-das-lojas")
        } catch (error) {
          if(error instanceof AxiosError)
            showTimeAlert(error.response?.data.message, "error")
          else
            showTimeAlert("Algo deu errado!", "error")
        }
        loaderStore.close()
      }

      const validatePhoneNumber = () => {
      const phone = group.value.responsible_telephone.replace(/[^0-9]/g, '');
      if (phone.length < 11 || phone.length > 13) {
        phoneError.value = 'O número de telefone deve ter 11 ou 12 dígitos.';
        phoneMask.value = '+55 (##) # ####-####';
      } else {
        phoneError.value = '';
        phoneMask.value = (phone.length < 13) ? '+55 (##) ####-####' : '+55 (##) # ####-####';
      }
    };

      const auxModal:any = ref(null);
      const openModal = id => {
          const auxElement = document.querySelector(`#${id}`);
          auxModal.value = new Modal(auxElement);
          auxModal.value.show();
        };
      const auxModalBack:any = ref(null);
      const openModalBack = id => {
          const auxElement = document.querySelector(`#${id}`);
          auxModalBack.value = new Modal(auxElement);
          auxModalBack.value.show();
        };

      return {
        isRequiredField,
        onSubmitForm,
        group,
        proportion,
        modatlCroppedLogo,
        openModalBack,
        auxModalBack,
        phoneError,
        validatePhoneNumber,
        phoneMask,

      }
    }
  })

